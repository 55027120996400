<template>
  <div class="site-nav">
    <div class="w w1200">
      <div class="fl">
        <div class="txt-info" v-show="!userInfo.account">
          <router-link :to="{path:'login'}" class="link-login red" tag="a">
            请登录
          </router-link>
        </div>
        <div class="txt-info" v-show="userInfo.account">
            <span>您好！&nbsp;
              <router-link :to="{path:'member'}" tag="a">
                {{ userInfo.account }}<template v-if="userInfo.realName">【{{ userInfo.realName }}】</template>
              </router-link>
            </span>
          <span>，欢迎来到{{webConfig.platformName}}&nbsp;&nbsp;</span>
          <span>[<a onclick="javascript:void(0)" v-on:click="logout" style="cursor: pointer">退出</a>]</span>
        </div>
      </div>
      <ul class="quick-menu fr">
<!--        <li>-->
<!--          <div class="dt">-->
<!--            <a onclick="javascript:void(0)">我的订单</a>-->
<!--          </div>-->
<!--        </li>-->
        <li :class="['li_dorpdown',{'hover':showAPP}]" @mouseover="showAPP=true" @mouseout="showAPP=false">
          <div class="dt dsc-cm">APP下载<i class="iconfont icon-down"></i></div>
          <div class="dd dorpdown-layer">
            <div class="qr-item">
              <div class="code_img"><img :src="require('@/assets/co/' + co + '/app.png')" width="116" height="116"></div>
            </div>
          </div>
        </li>
        <li :class="['li_dorpdown',{'hover':showCode}]" @mouseover="showCode=true" @mouseout="showCode=false">
          <div class="dt dsc-cm">微信服务号<i class="iconfont icon-down"></i></div>
          <div class="dd dorpdown-layer">
            <div class="qr-item">
              <div class="code_img"><img :src="require('@/assets/co/' + co + '/fwh.png')" width="116" height="116"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "TopNav",
  data() {
    return {
      userInfo: {},
      showCode:false,
      showAPP: false,
    }
  },
  computed:{
    ...mapGetters(['cartNum']),
    webConfig() {
      return this.$store.state.settings.webConfig
    },
  },
  created() {
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.$store.commit('SET_ISLOGIN', true)
      this.$store.dispatch('GetCartSum')
    }
  },
  methods:{
    logout(){
      localStorage.removeItem('userToken')
      localStorage.removeItem('userInfo')
      this.$store.commit('SET_CARTNUM', 0)
      this.$store.commit('SET_CARTLIST', [])
      this.$store.commit('SET_CARTPRICE',0)
      this.$store.commit('SET_ISLOGIN', false)
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.quick-menu li.li_dorpdown .dorpdown-layer{
  padding: 0;
  min-width: 0;
}

.quick-menu li.li_dorpdown .dorpdown-layer .qr-item{
  padding:16px;
}
</style>
