<template>
  <div class="footer-new">
    <div class="footer-new-top">
      <div class="w w1200">
        <div class="service-list">
          <div class="service-item">
            <i class="f-icon f-icon-zheng"></i>
            <span>正品保障</span>
          </div>
          <div class="service-item">
            <i class="f-icon f-icon-hao"></i>
            <span>好评如潮</span>
          </div>
          <div class="service-item">
            <i class="f-icon f-icon-shan"></i>
            <span>闪电发货</span>
          </div>
          <div class="service-item">
            <i class="f-icon f-icon-quan"></i>
            <span>权威荣誉</span>
          </div>
        </div>
        <div class="contact">
          <div class="contact-item contact-item-first">
            <i class="f-icon f-icon-tel"></i>
            <span>{{webConfig.telephoneCustomer}}</span>
          </div>
          <div class="contact-item">
            <i class="f-icon f-icon-kefu"></i>
            <span>{{webConfig.telephoneAfterSales}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-new-con">
      <div class="fnc-warp">
        <div class="help-list">
          <div class="help-item">
            <h3>配送与支付</h3>
            <ul>
              <li v-for="(item, index) in list1" :key="index">
                <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">{{item.title}}</router-link>
              </li>
            </ul>
          </div>
          <div class="help-item">
            <h3>新手上路</h3>
            <ul>
              <li v-for="(item, index) in list2" :key="index">
                <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">{{item.title}}</router-link>
              </li>
            </ul>
          </div>
          <div class="help-item">
            <h3>会员中心</h3>
            <ul>
              <li v-for="(item, index) in list3" :key="index">
                <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">{{item.title}}</router-link>
              </li>
            </ul>
          </div>
          <div class="help-item">
            <h3>服务保证</h3>
            <ul>
              <li v-for="(item, index) in list4" :key="index">
                <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">{{item.title}}</router-link>
              </li>
            </ul>
          </div>
          <div class="help-item">
            <h3>联系我们</h3>
            <ul>
              <li v-for="(item, index) in list5" :key="index">
                <router-link :to="{path:'/article/view',query:{id:item.id}}" target="_blank" tag="a">{{item.title}}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="qr-code">
          <div class="qr-item qr-item-first">
            <div class="code_img"><img :src="require('@/assets/co/' + co + '/app.png')"></div>
            <div class="code_txt">微信服务号</div>
          </div>
          <div class="qr-item">
            <div class="code_img"><img :src="require('@/assets/co/' + co + '/app.png')"></div>
            <div class="code_txt">微购物</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-new-bot">
      <div class="w w1200">
        <p class="copyright_links">
          <router-link :to="{path:'/'}" target="_blank" tag="a">首页</router-link><span class="spacer"></span>
          <router-link :to="{path:'/article/view',query:{id:'468294053247670469'}}" target="_blank" tag="a">隐私保护</router-link><span class="spacer"></span>
          <router-link :to="{path:'/article/view',query:{id:'468285037163403461'}}" target="_blank" tag="a">联系我们</router-link><span class="spacer"></span>
          <router-link :to="{path:'/article/view',query:{id:'468291269479717061'}}" target="_blank" tag="a">免责条款</router-link><span class="spacer"></span>
          <router-link :to="{path:'/article/view',query:{id:'468285518149408965'}}" target="_blank" tag="a">公司简介</router-link>
        </p>
        <p>
<!--          <span>©&nbsp;2023&nbsp;hc123.com.cn&nbsp;版权所有&nbsp;&nbsp;</span>-->
          <template v-for="(item) in certificateList">
            <span :key="item.name">{{item.name}}：</span>
            <a :key="item.website" v-if="item.website" :href="item.website" target="_blank">{{item.number}}</a>
            <a :key="item.number" v-else :href="staticUrl + '/' + item.imageUrl" target="_blank">{{item.number}}</a>&nbsp;&nbsp;&nbsp;
          </template>
          POWERED by&nbsp;&nbsp;<a href="">{{webConfig.platformName}}</a>
        </p>
        <p class="copyright_auth">&nbsp;</p>
      </div>
    </div>

  </div>
</template>

<script>
import {getArticlePaging} from '@/api/article'
import {getList as getCertificateList} from '@/api/mall/base/certificate'
export default {
  name: "Index",
  computed:{
    webConfig() {
      return this.$store.state.settings.webConfig
    },
  },
  data() {
    return {
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      certificateList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467127377076507397'}).then(res => {
        this.list1 = res.data.list
      });

      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130134068024261'}).then(res => {
        this.list2 = res.data.list
      });

      getArticlePaging({pageSize:5,currentPage:1,categoryId:'468274427495274693'}).then(res => {
        this.list3 = res.data.list
      });

      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130273889342405'}).then(res => {
        this.list4 = res.data.list
      });

      getArticlePaging({pageSize:5,currentPage:1,categoryId:'467130215269749701'}).then(res => {
        this.list5 = res.data.list
      });

      getCertificateList().then(res => {
        this.certificateList = res.data.list
      })
    }
  }
}
</script>

<style scoped>
.qr-code {
  width: 276px;
  margin: 0 auto;
}
</style>
